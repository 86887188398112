import Flickity from "flickity";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { getById, isLoaded, pad, closestWidth} from "../../utils";
import detect from '../../utils/detect';
import Canvas from "./canvas";
import VideoNavigation from './videoNavigation';

const Wrapper = ({ children, forwardedRef }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full select-none">
      {children}
    </div>
  )
}

const NavigationWrapper = ({ children, forwardedRef }) => {
  return (
    <div
      ref={forwardedRef}
      className="absolute bottom-0 w-full h-16 lg:h-20 z-10 overflow-hidden"
    >
      <div>
        {children}
      </div>
      <div className="absolute top-0 w-full h-px bg-white opacity-25"></div>
    </div>
  )
}

const Navigation = ({ forwardedRef, next, prev, select, current, data }) => {
  const { navigationBar, navigationList } = forwardedRef
  return (
    <div
      className="relative top-0 w-full h-full overflow-hidden"
      ref={navigationBar}
    >
      <div className="text-sm leading-none text-gray" ref={navigationList} cursor="drag">
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className="pl-5 h-16 lg:h-20 pr-24 lg:pr-32 xl:pr-40 flex-shrink-0 flex items-center whitespace-no-wrap"
            >
              <div className="flex items-center hover:text-yellow transition duration-500 ease-in-out" cursor="active">
                <div className="arrow-top mr-2"></div>
                {pad(index + 1, 2)} — {item.label}
              </div>
            </div>
          )
        })}
      </div>
      <div className="absolute top-0 right-0 bg-black h-16 lg:h-20 w-32 hidden lg:flex items-center justify-center">

        <div className="absolute top-0 left-0 w-1/2 h-full" onClick={prev} cursor="active"></div>
        <div className="absolute top-0 right-0 w-1/2 h-full" onClick={next} cursor="active"></div>

        <div className="absolute top-0 left-0 w-px h-full bg-white opacity-25"></div>
        <div className="px-4 py-8">
          <div className="arrow-left"></div>
        </div>
        <div className="text-xs">
          <span className="text-white">{pad(current + 1, 2)}</span>{" "}
          <span className="text-gray"> — {pad(data.length, 2)}</span>
        </div>
        <div className="px-4 py-8">
          <div className="arrow-right"></div>
        </div>
      </div>
    </div>
  )
}


const BackBtn = ({onClick}) => {
  return (
    <div
      className="flex items-center text-xs leading-none hover:text-yellow cursor-pointer transition ease-in-out duration-300"
      onClick={onClick}
      cursor="active"
    >
      <svg
        style={{marginTop: '0.15rem'}}
        className="w-3 mr-2 h-auto fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 10"
      >
        <g fillRule="evenodd">
          <path d="M7 9.333334V6.7e-7L0 4.84414714zM14 9.333334V6.7e-7L7 4.84414714z" />
        </g>
      </svg>
      Back to overview
  </div>
  )
}

const MobileBackBanner = ({back, forwardedRef}) => (
  <div ref={forwardedRef} className="absolute top-0 left-0 mt-16 w-full z-20 h-12 lg:hidden opacity-0">
    <div className="container">
      <BackBtn onClick={back} />
    </div>
  </div>
)

const Info = ({ data, current, forwardedRef }) => {
  const { glyphs, year, infoRow } = forwardedRef

  return (
    <div className="absolute top-0 left-0 w-full container mt-24 z-10 hidden lg:block pointer-events-none">
      <div className="row" ref={infoRow}>
        <div className="relative col w-2/12 text-sm text-gray" ani-reveal-in="true">
          {data.map((item, index) => {
            return (
              <h1
                className={`absolute animation-overview-text-change ${
                  current == index ? "active" : "0"
                }`}
                key={index}
              >
                {item.title}
              </h1>
            )
          })}
        </div>
        <div className="col w-4/12 text-sm">
          <div className="relative max-w-xs" ani-reveal-in="true">
            {data.map((item, index) => {
              return (
                <h1
                  className={`absolute animation-overview-text-change animation-overview-text-change-delay-2 ${
                    current == index ? "active" : "0"
                  }`}
                  key={index}
                >
                  <span className="is-lang-en">
                    {item.desc}
                  </span>

                  <span className="is-lang-ja font-ja" dangerouslySetInnerHTML={{__html: item.descJa}}>

                  </span>
                </h1>
              )
            })}
          </div>
        </div>
        <div className="col w-3/12 text-xs">
          <p className="flex justify-between mb-6" ani-reveal-in="true">
            <span className="text-gray">Glyphs:{" "}</span>
            <span ref={glyphs}>0</span>
          </p>
          <p className="flex justify-between" ani-reveal-in="true">
            <span className="text-gray">Year:{" "}</span>
            <span ref={year}>0</span>
          </p>
        </div>
        <div className="col w-3/12 text-xs">
          <p
            className="relative flex justify-between mb-6"
            ani-reveal-in="true"
          >
            <span className="text-gray"> Name:</span>
            {data.map((item, index) => {
              return (
                <span key={index}
                  className={`block absolute right-0 animation-overview-text-change animation-overview-text-change-delay-3 ${
                    current == index ? "active" : "0"
                  }`}
                >
                  {item.name}
                </span>
              )
            })}
          </p>
        </div>
      </div>
    </div>
  )
}

const InfoCompact = ({ data, current, forwardedRef }) => {
  return (
    <div ref={forwardedRef}>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            ref={forwardedRef}
            className={`absolute bottom-0 left-0 w-full container mb-24 z-10 text-sm block lg:hidden ${
              current == index ? "" : "opacity-0"
            }`}
          >
            <h1 className="mb-2">{item.title}</h1>
            <div className="max-w-xs text-gray">
              <p className="is-lang-en">
                {item.desc}
              </p>
              <p className="is-lang-ja font-ja" dangerouslySetInnerHTML={{__html: item.descJa}}></p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
const Overview = ({ data, videos, transitionStatus }) => {

  const navigationInstance = useRef(null)
  const glyphs = useRef(null)
  const year = useRef(null)
  const video = useRef(null)
  const navigationWrapper = useRef(null)
  const navigationList = useRef(null)
  const navigationBar = useRef(null)
  const videoBar = useRef(null)
  const mobileBackBanner = useRef(null);
  const progressBars = useRef([]);
  const progressBarsMobile = useRef([]);
  const infoRow = useRef(null)
  const infoCompact = useRef(null)
  const scene = useRef(null)

  const lockNavigation = useRef(false);

  const videoStatus = useRef({
    current: 0,
    duration: 0,
  })

  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
  const [onOverview, setOnOverview] = useState(true)

  useEffect(() => {
    revealSetup()
    if (!isLoaded()) {
      window.addEventListener("loadingDone", revealScene)
    }

    setupNavigation()

    return () => {
      window.removeEventListener("keydown", checkKey)
      if (navigationInstance.current) {
        navigationInstance.current.destroy()
      }
    }
  }, [])

  useEffect(() => {
    lockNavigation.current = !onOverview;
  },[onOverview]);

  useEffect(() => {
    if (isLoaded() && transitionStatus == "entered") {
      revealScene()
    }

    if (transitionStatus == "exiting") {
      exitScene()
    }
  }, [transitionStatus])

  useEffect(() => {
    video.current.addEventListener("timeupdate", onTimeUpdate)
    video.current.addEventListener("ended", onEnded)

    resetProfressbars();

    resolveVideo(currentIndex, currentVideoIndex);

    return () => {
      video.current.removeEventListener("timeupdate", onTimeUpdate)
      video.current.removeEventListener("ended", onEnded)
    }
  }, [currentVideoIndex, currentIndex])


  const exitScene = () => {
    gsap.to([infoRow.current, infoCompact.current], {
      opacity: 0,
      duration: 0.5,
      stagger: {
        amount: 0.2,
      },
    })

    gsap.fromTo(
      navigationWrapper.current,
      {
        yPercent: 0,
        opacity: 0,
      },
      {
        yPercent: 100,
        duration: 0.5,
        opacity: 1,
      }
    )

    gsap.to(scene.current, {
        opacity:0,
        duration:0.5,
        delay:0.5,
    });
  }

  const revealSetup = () => {

    gsap.set(infoCompact.current.children, {
      opacity:0
    });
    gsap.set(navigationList.current, {
      x: 100,
      opacity: 0,
    })

    gsap.set([scene.current], {
      opacity:0,
    });
  
    gsap.set(infoRow.current.querySelectorAll("[ani-reveal-in]"),
      {
        opacity: 0,
        y: 60,
      }
    )
  }
  const revealScene = () => {

    gsap.to(infoCompact.current.children[currentIndex], {
      opacity:1,
      delay:0.8,
      ease: "power2.inOut",
    });

    gsap.to(infoRow.current.querySelectorAll("[ani-reveal-in]"),
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        ease: "power2.inOut",
        stagger: {
          amount: 0.6,
        },
      }
    )

    gsap.to(navigationList.current, {
      x: 0,
      opacity: 1,
      duration: 1.5,
      ease: "power2.inOut",
    })

    gsap.to(scene.current, {
      opacity:1,
      duration:0.5,
  });

    window.removeEventListener("loadingDone", revealScene)
  }

  const onTimeUpdate = () => {
    videoStatus.current.duration = video.current.duration
    videoStatus.current.current = video.current.currentTime

    resetProfressbars();

    gsap.set([progressBars.current[currentVideoIndex],progressBarsMobile.current[currentVideoIndex]], {
      xPercent:
        (videoStatus.current.current / videoStatus.current.duration) * 100,
    });
  }
  
  const resetProfressbars = () => {
    progressBars.current.map(bar => {       
      if(bar) {
        gsap.set(bar, {
          xPercent: 0,
        })
      }
    })

    progressBarsMobile.current.map(bar => {       
      if(bar) {
        gsap.set(bar, {
          xPercent: 0,
        })
      }
    });
  }

  const onEnded = () => {
    if (currentVideoIndex >= data[currentIndex].vidoes.length - 1) {
      setCurrentVideoIndex(0)
    } else {
      setCurrentVideoIndex(currentVideoIndex => currentVideoIndex + 1)
    }
  }

  const prev = () => {
    if (!navigationInstance) return

    if (navigationInstance.current.isAnimating || lockNavigation.current) return

    navigationInstance.current.previous()
  }

  const next = () => {
    if (!navigationInstance) return
    if (navigationInstance.current.isAnimating || lockNavigation.current) return
    console.log('next');
    navigationInstance.current.next()
  }

  const select = (event, pointer, cellElement, cellIndex) => {
    if (!navigationInstance) return

    if (navigationInstance.current.isAnimating) return

    navigationInstance.current.select(cellIndex, false)
  }

  const setCurrentVideo = index => {
    setCurrentVideoIndex(index)
    playVideo()
  }
  const setupNavigation = () => {
    navigationInstance.current = new Flickity(navigationList.current, {
      cellAlign: "left",
      percentPosition: false,
      wrapAround: true,
      pageDots: false,
      prevNextButtons: false,
      accessibility: false,
    })
    
    navigationInstance.current.on("change", onchange)
    navigationInstance.current.on("staticClick", select)

    navigationInstance.current.on("dragMove", function(
      event,
      pointer,
      moveVector
    ) {
      window.dispatchEvent(
        new CustomEvent("mouseDrag", {
          detail: {
            event,
          },
        })
      )
    })

    window.addEventListener("keydown", checkKey)

    if (isLoaded()) {
      updateNumber(0, 0)
    } else {
      window.addEventListener("loadingDone", () => {
        updateNumber(0, 0)
      })
    }
  }

  const checkKey = e => {
    e = e || window.event

    if (e.keyCode == "38") {
      // up arrow
      next()
    } else if (e.keyCode == "40") {
      // down arrow
      prev()
    } else if (e.keyCode == "37") {
      // left arrow
      prev()
    } else if (e.keyCode == "39") {
      // right arrow
      next()
    } else if (e.keyCode == "32") {
      next()
    }
  }

  const openPlayer = () => {
    if (!onOverview) return

    setCurrentVideoIndex(0);

    setOnOverview(false)
    playVideo()

    gsap.to(infoRow.current.children, {
      opacity: 0,
      stagger: {
        amount: 0.2,
      },
    })

    gsap.to(infoCompact.current, {
      opacity: 0,
    });

    gsap.fromTo(
      videoBar.current,
      {
        yPercent: 0,
        opacity: 0,
      },
      {
        yPercent: -100,
        opacity: 1,
      }
    )

    gsap.to(navigationBar.current, {
      yPercent: -100,
      opacity: 0,
    });

    gsap.to(mobileBackBanner.current, {
      opacity:1
    });
  }

  const playVideo = () => {
    video.current.load();
  }

  const pauseVideo = () => {
    video.current.pause()
  }

  const backToOverview = () => {
    if (onOverview) return

    setOnOverview(true)

    pauseVideo()

    gsap.to(infoRow.current.children, {
      opacity: 1,
      y: 0,
    });

    gsap.to(infoCompact.current, {
      opacity: 1,
    });

    gsap.to(videoBar.current, {
      yPercent: 0,
      opacity: 0,
    })

    gsap.to(navigationBar.current, {
      yPercent: 0,
      opacity: 1,
    })

    gsap.to(mobileBackBanner.current, {
      opacity:0
    });
  }

  const onchange = index => {
    navigationInstance.current.isDraggable = false;

    setTimeout(() => {
      navigationInstance.current.isDraggable = true;
     },1100);
    setCurrentIndex(index)
    setCurrentVideoIndex(0)
    updateNumber(undefined, index)
    updateText(index);
  }

  const updateText = index => {
    
    gsap.to(infoCompact.current.children, {
      opacity:0,
      onComplete: () => {
        gsap.to(infoCompact.current.children[index], {
          opacity:1
        });
      }
    });
  }

  const updateNumber = (startFrom, index) => {
    const glyphsNumber = {
      current:
        startFrom !== undefined
          ? startFrom
          : parseInt(glyphs.current.innerHTML),
    }

    const yearNumber = {
      current:
        startFrom !== undefined ? startFrom : parseInt(year.current.innerHTML),
    }

    gsap.to(glyphsNumber, {
      current: data[index].glyphs,
      duration: 1.8,
      onUpdate: () => {
        if (!glyphs.current) return
        glyphs.current.innerHTML = Math.round(glyphsNumber.current)
      },
    })

    gsap.to(yearNumber, {
      current: data[index].year,
      duration: 1.8,
      onUpdate: () => {
        if (!year.current) return
        year.current.innerHTML = Math.round(yearNumber.current)
      },
    })
  }


  const resolveVideo = (currentIndex, currentVideoIndex) => {
    var videoUrl = data[currentIndex] && data[currentIndex].vidoes[currentVideoIndex] ? closestWidth(getById(videos.nodes,data[currentIndex].vidoes[currentVideoIndex].videoId).srcset, 1280).link : null;

    if(!videoUrl) return;

    if(detect.isSafari() || detect.isMobile()) {
      fetch(videoUrl).then(data => {
        if(video && video.current) {
          video.current.setAttribute('src',data.url);
        }
      });
    } else {
      video.current.setAttribute('src',videoUrl);
    }
  }

  return (
    <>
    <Wrapper>
      <MobileBackBanner back={backToOverview} forwardedRef={mobileBackBanner} />
      <Info
        data={data}
        current={currentIndex}
        forwardedRef={{
          infoRow,
          glyphs,
          year,
        }}
      />
      <InfoCompact
        data={data}
        current={currentIndex}
        forwardedRef={infoCompact}
      />
      <video
        ref={video}
        crossOrigin="anonymous"
        muted={true}
        autoPlay={true}
        playsInline={true}
        src={data[currentIndex] && data[currentIndex].vidoes[currentVideoIndex] ? closestWidth(getById(videos.nodes,data[currentIndex].vidoes[currentVideoIndex].videoId).srcset, 1280).link : ''}
        poster="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
        className="absolute opacity-0 pointer-events-none"
      ></video>
      <Canvas
        forwaredRef={scene}
        data={data}
        video={video}
        current={currentIndex}
        onSelect={openPlayer}
        onOverview={onOverview}
        aspectRatio={
          data[currentIndex] && data[currentIndex].vidoes[currentVideoIndex] ?
          getById(
            videos.nodes,
            data[currentIndex].vidoes[currentVideoIndex].videoId
          ).aspectRatio : 1
        }
      />
      <NavigationWrapper forwardedRef={navigationWrapper}>
        <Navigation
          data={data}
          forwardedRef={{
            navigationList,
            navigationBar,
          }}
          next={next}
          prev={prev}
          select={select}
          current={currentIndex}
        />
        {typeof document !== `undefined` &&
          <VideoNavigation
            forwardedRef={{ progressBars,progressBarsMobile, videoBar}}
            data={data}
            current={currentIndex}
            currentVideo={currentVideoIndex}
            setCurrentVideo={setCurrentVideo}
            back={backToOverview}
          />
        }
      </NavigationWrapper>
    </Wrapper>
    </>
  )
}

export default Overview
