import React, { useEffect, useRef } from "react";
import Flickity from "react-flickity-component";

const VideoNavigation = ({
    forwardedRef,
    current,
    currentVideo,
    setCurrentVideo,
    back,
    data,
  }) => {

    const { videoBar, progressBars, progressBarsMobile } = forwardedRef;

    const slider = useRef(undefined);

    useEffect(() => {
        if(slider.current == undefined) return;
        select(0);
    },[current]);

    const select = (index) => {
        slider.current.select(index,false,false);
    }

    const flickityOptions = {
        cellAlign: "left",
        // draggable: false,
        contain: true,
        freeScroll: true,
        pageDots: false,
        prevNextButtons: false,
        accessibility: false,
      };

    return (
    <div className="relative top-0 w-full h-16 lg:h-20 md:flex md:justify-center overflow-hidden" ref={videoBar}>

        <div className="absolute top-0 left-0 h-full container w-auto hidden lg:flex lg:items-center">
            <div
                className="flex items-center text-xs leading-none hover:text-yellow cursor-pointer transition ease-in-out duration-300"
                onClick={back}
                cursor="active"
                >
                <svg
                    className="w-3 mr-2 h-auto fill-current"
                    style={{marginTop: '0.1rem'}}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 10"
                >
                    <g fillRule="evenodd">
                    <path d="M7 9.333334V6.7e-7L0 4.84414714zM14 9.333334V6.7e-7L7 4.84414714z" />
                    </g>
                </svg>
                Back to overview
            </div>
        </div>

        <Flickity className="md:hidden h-full" options={flickityOptions} flickityRef={c => slider.current = c}>
            {data[current].vidoes.map((video, index) => {
                return (
                <div
                    key={index}
                    className={`relative text-xs leading-none text-gray hover:text-yellow h-16 lg:h-20 flex items-center flex-shrink-0 px-4 overflow-hidden cursor-pointer transition ease-in-out duration-300 ${
                    index == currentVideo ? "text-yellow" : ""
                    }`}
                    onClick={() => {
                        setCurrentVideo(index);
                        select(index);
                    }}
                    cursor="active"
                >
                    <div
                    className="absolute top-0 left-0 ml-4 h-px overflow-hidden"
                    style={{ width: "calc(100% - 2rem)" }}
                    >
                        <div style={{ left: "-100%" }} className="absolute top-0 left-0 w-full h-full bg-yellow" ref={item => {
                            progressBarsMobile.current[index] = item
                        }}></div>
                    </div>
                    <div className="inline-block align-middle h-2 w-2 mr-2">
                    {index == currentVideo ? (
                        <svg
                        className="fill-current h-full"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 7 9"
                        >
                        <g fillRule="evenodd">
                            <path d="M0 0h2v9H0zM5 0h2v9H5z" />
                        </g>
                        </svg>
                    ) : (
                        <svg
                        className="fill-current h-full w-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 7 10"
                        >
                        <path fillRule="evenodd" d="M0 0v9.333333l7-4.844146z" />
                        </svg>
                    )}
                    </div>
                    {video.label}
                </div>
            )
            })}
        </Flickity>
        
        <div className="hidden md:flex flex-no-wrap h-full">
        
            {data[current].vidoes.map((video, index) => {
                return (
                <div
                    key={index}
                    className={`relative text-xs leading-none text-gray hover:text-yellow h-16 lg:h-20 flex items-center flex-shrink-0 px-4 overflow-hidden cursor-pointer transition ease-in-out duration-300 ${
                    index == currentVideo ? "text-yellow" : ""
                    }`}
                    onClick={() => setCurrentVideo(index)}
                    cursor="active"
                >
                    <div
                    className="absolute top-0 left-0 ml-4 h-px overflow-hidden"
                    style={{ width: "calc(100% - 2rem)" }}
                    >
                        <div style={{ left: "-100%" }} className="absolute top-0 left-0 w-full h-full bg-yellow" ref={item => {
                            progressBars.current[index] = item
                        }}></div>
                    </div>
                    <div className="inline-block align-middle h-2 w-2 mr-2">
                    {index == currentVideo ? (
                        <svg
                        className="fill-current h-full"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 7 9"
                        >
                        <g fillRule="evenodd">
                            <path d="M0 0h2v9H0zM5 0h2v9H5z" />
                        </g>
                        </svg>
                    ) : (
                        <svg
                        className="fill-current h-full w-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 7 10"
                        >
                        <path fillRule="evenodd" d="M0 0v9.333333l7-4.844146z" />
                        </svg>
                    )}
                    </div>
                    {video.label}
                </div>
            )
            })}
        </div>
    </div>
    )
  }

  export default VideoNavigation;