import gsap from 'gsap';

const duration = 1.1;
const ease = "power3.inOut";

const prevComplete = (prev) => {
    prev.parent.position.x = 0;
    prev.parent.position.y = 0;
    prev.rotation.x = 1.5708;
    prev.rotation.y = 0;
    prev.rotation.z = 0;
    prev.visible = false;


    console.log('on complete!',prev);
}

const hasRotation = () => Math.random() > 0.5;
  
const up = (prev, curr, store) => {

    if(hasRotation()) {
        
        gsap.to(prev.rotation,{
                x:0,
                duration: duration,
                ease: ease,
            }
        );

        gsap.fromTo(curr.rotation,{
                x:1.5708 * 2,
            }, {
                x:1.5708,
                duration: duration,
                ease: ease,
            }
        );
    }

    gsap.to(prev.parent.position, {
        y: store.current.h,
        duration: duration,
        ease: ease,
        onComplete: () => prevComplete(prev),
    });

    gsap.fromTo(curr.parent.position,{
        y: store.current.h * -1,
        onStart: () => {
            curr.visible = true
        },
        },
        {
            y: 0,
            duration: duration,
            ease: ease,
        }
    );

}


const down = (prev, curr, store) => {

    if(hasRotation()) {
        
        gsap.to(prev.rotation,{
                x:1.5708*2,
                duration: duration,
                ease: ease,
            }
        );

        gsap.fromTo(curr.rotation,{
                x:0,
            }, {
                x:1.5708,
                duration: duration,
                ease: ease,
            }
        );
    }

    gsap.to(prev.parent.position, {
        y: store.current.h * -1,
        duration: duration,
        ease: ease,
       onComplete: () => prevComplete(prev),
    });

    gsap.fromTo(curr.parent.position,{
        y: store.current.h,
        onStart: () => {
            curr.visible = true
        },
        },
        {
            y: 0,
            duration: duration,
            ease: ease,
        }
    );

}

const left = (prev, curr, store) => {

    if(hasRotation()) {
        
        gsap.to(prev.rotation,{
                z:1.5708/2,
                duration: duration,
                ease: ease,
            }
        );

        gsap.fromTo(curr.rotation,{
                z:(1.5708*2)/2 * -1,
            }, {
                z:0,
                duration: duration,
                ease: ease,
            }
        );
    }

    gsap.to(prev.parent.position, {
        x: store.current.w * -1,
        duration: duration,
        ease: ease,
        onComplete: () => prevComplete(prev),
    });

    gsap.fromTo(curr.parent.position,{
        x: store.current.w,
        onStart: () => {
            curr.visible = true
        },
        },
        {
            x: 0,
            duration: duration,
            ease: ease,
        }
    );

}

const right = (prev, curr, store) => {

    if(hasRotation()) {
        
        gsap.to(prev.rotation,{
                z:1.5708/2 * -1,
                duration: duration,
                ease: ease,
            }
        );

        gsap.fromTo(curr.rotation,{
                z:(1.5708*2)/2,
            }, {
                z:0,
                duration: duration,
                ease: ease,
            }
        );
    }

    gsap.to(prev.parent.position, {
        x: store.current.w,
        duration: duration,
        ease: ease,
       onComplete: () => prevComplete(prev),
    });

    gsap.fromTo(curr.parent.position,{
        x: store.current.w * -1,
        onStart: () => {
            curr.visible = true
        },
        },
        {
            x: 0,
            duration: duration,
            ease: ease,
        }
    );

}

export default (prev, curr, store, transition) => {
    
    if(transition == 'up') {
        up(prev, curr, store);
    } else if (transition == 'down') {
        down(prev, curr, store);
    }
    else if (transition == 'left') {
        left(prev, curr, store);
    }
    else if (transition == 'right') {
        right(prev, curr, store);
    }
} 