import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/seo"
import Overview from '../partials/overview';
import asics from '../3d/v3/asics.obj';
import carlsberg from '../3d/carlsberg.obj';
import dda from '../3d/dda.obj';
// import goertek from '../3d/goertek.obj';
import noma from '../3d/nomav2.obj';
import rescape from '../3d/rescape.obj';
import shisheido from '../3d/v3/shiseido.obj';
import tasaki from '../3d/tasaki.obj';
import vivo from '../3d/vivo.obj';


const IndexPage = ({transitionStatus}) => {

  const data = [{
    'name' : 'Asics Tiger',
    'label': 'Asics',
    'title' : 'Rivitalising an icon',
    'glyphs' : 126,
    'year' : 2017,
    'desc': 'In close collaboration with Bruce Mau Design, Kontrapunkt turned an iconic logo into a broad typeface. Together, we created a unique brand typeface for ASICS Tiger to use worldwide.',
    'descJa': 'Bruce Mau DesignとのコラボレーションによるASICS Tigerブランドタイプフェイス。アイコニックな既存ロゴを拡張したこのタイプフェイスは世界へ展開され、ブランドに更なる活気とエネルギーを与える要素となった。',
    'vidoes': [{
      label : 'Concept',
      videoId: 392198008,
    },{
      label : 'Spotlight',
      videoId: 392198647,
    },{
      label : 'Specimen',
      videoId: 392198523,
    },{
      label : 'Application',
      videoId: 392198722,
    },{
      label : 'Extra',
      videoId: 392198887,
    }],
    'obj': asics,
    transition: 'left',
  },
  {
    'name' : 'Vivo',
    'label': 'Vivo',
    'title' : 'Confidence / Youth / Tech / Curiosity',
    'glyphs' : 3000,
    'year' : 2017,
    'desc': 'Reflecting the uniqueness and confidence of the brand, the Vivo typeface secures consistency in communication globally across all platforms. The typeface references the Vivo logo of which the characteristics are youth and technology.',
    'descJa': '中国のスマートフォンメーカーVivoのユニークさが反映されたブランドタイプフェイス。このブランドタイプフェイスにより、国境を超えた様々なプラットホームにおけるコミュニケーションに一貫性が生まれた。',
    'vidoes': [{
      label : 'Concept',
      videoId: 396799500,
    },{
      label : 'Specimen',
      videoId: 396799565,
    },{
      label : 'Application',
      videoId: 396799624,
    },{
      label : 'Extra',
      videoId: 396799810,
    }],
    'obj': vivo,
    transition: 'up',
  },
  {
    'name' : 'Noma',
    'label': 'Noma',
    'title' : 'Natural perfection',
    'glyphs' : 323,
    'year' : 2018,
    'desc': 'The typeface, designed for noma, is organic and rooted in the rugged Scandinavian nature. It is soft and rounded but has an uneven and earthly feel to it. The letters are drawn by hand, creating organic shapes and breaking traditional conventions about typography being perfectly aligned.',
    'descJa': 'デンマークの首都、コペンハーゲンにある世界的に有名なレストラン、nomaのためのブランドタイプフェイス。手描きで制作された優しく丸みのある形状は不均等さもあり、自然でオーガニックな印象を与える。',
    'vidoes': [{
      label : 'Inspiration',
      videoId: 396883607,
    },{
      label : 'Spotlight',
      videoId: 396883870,
    },{
      label : 'Specimen',
      videoId: 396883932,
    }],
    'obj': noma,
    transition: 'right',
  },
  {
    'name' : 'Spark',
    'label': 'Shiseido',
    'title' : 'Awakening cultural heritage to new beginnings',
    'glyphs' : 128,
    'year' : 2019,
    'desc': 'For the Shiseido Innovation Center, we created a fitting bespoke typeface with sparks. The typeface has a range of alternate characters which can carefully, but seemingly randomly, be used to provoke sparks in writing. It is energetic and encompasses innovation and creativity, just like the innovation centre itself.',
    'descJa': '資生堂グローバルイノベーションセンター <span class="inline-block">＜S/PARK＞</span>のためのタイプフェイス。革新性と創造性を持ち合わせた同センターを体現したこのタイプフェイスは、エネルギーに溢れたモダンで未来志向のデザインである一方、資生堂書体を代表とするSHISEIDOの伝統にもしっかりと根付いている。',
    'vidoes': [{
      label : 'Concept',
      videoId: 396803051,
    },{
      label : 'Specimen',
      videoId: 396803123,
    },{
      label : 'Spotlight',
      videoId: 396803097,
    },{
      label : 'Application',
      videoId: 396803148,
    }],
    'obj': shisheido,
    transition: 'down',
  }, {
    'name' : 'Tasaki',
    'label': 'Tasaki',
    'title' : 'Delicate writing',
    'glyphs' : 93,
    'year' : 2009,
    'desc': 'The reference point for creating a new custom typeface, was building on the heritage and reputation of Tasaki and guiding it towards a more youthful identity and expression. The bespoke typeface is elegant and balances soft, delicate brushes at the end of each letter.',
    'descJa': 'ジュエリーブランドTASAKIのためのオーダーメイドタイプフェイス。日本の書道からインスピレーションを得たこのエレガントなタイプフェイスは、文字の最後が柔らかく繊細な筆使いで整えられ、TASAKIの伝統と名声、そこから若さに満ちたアイデンティティへの発展が表現されている。',
    
    'vidoes': [{
      label : 'Inspiration',
      videoId: 396805602,
    },{
      label : 'Specimen',
      videoId: 396805746,
    },{
      label : 'Spotlight',
      videoId: 396805702,
    },{
      label : 'Application',
      videoId: 396805772,
    }],
    'obj': tasaki,
    transition: 'right',
  },
  {
    'name' : 'Ushio',
    'label': 'Rescape',
    'title' : 'In the hands of nature',
    'glyphs' : 93,
    'year' : 2019,
    'desc': 'The bespoke typeface, for The Rescape, is the erosion of a typeface. The letters start off bold and robust, becoming smoother and more rounded as time passes. It is a typeface which develops and changes appearance – just like natural elements left in the sea.',
    'descJa': '宮古島の大自然に溶け込むリゾートホテル、Rescapeのための特注タイプフェイス。海辺の岩や流木、貝殻は、砂浜で転がりこすられることで、時間の経過とともに尖った縁は丸くなる。その侵食の過程を、文字に表現することで、大胆で力強く始まり、次第に滑らかに丸みを帯びていく、常に変化を続ける自然の一部のようなタイプフェイスとなった。',
    'vidoes': [{
      label : 'Concept',
      videoId: 396807084,
    },{
      label : 'Inspiration',
      videoId: 396806787,
    },{
      label : 'Specimen',
      videoId: 396807126,
    },{
      label : 'Spotlight',
      videoId: 396807103,
    }],
    'obj': rescape,
    transition: 'up',
  },
  {
    'name' : 'Carlsberg Slab',
    'label': 'Carlsberg',
    'title' : 'Building recognition',
    'glyphs' : 800,
    'year' : 2017,
    'desc': 'The Carlsberg Slab typeface has made it possible for Carlsberg to type with their logo. Since the typeface saw the light of day, recognition has risen to a level which allows Carlsberg to brand itself by merely using the typeface itself. ',
    'descJa': '世界中で愛されるビール、カールスバーグのためのタイプフェイス。ロゴと併用するために作られたこのカールスバーグ・スラブ・タイプフェイスは、そのデビュー以来、このタイプフェイスを使うだけでカールスバーグのブランドを示すことができるレベルにまで認知度が上がっている。',
    'vidoes': [{
      label : 'Concept',
      videoId: 396810092,
    },{
      label : 'Inspiration',
      videoId: 396809872,
    },{
      label : 'Specimen',
      videoId: 396810120,
    },{
      label : 'Extra',
      videoId: 396810161,
    }],
    'obj': carlsberg,
    transition: 'left',
  },
  {
    'name' : 'Danish',
    'label': 'DDA',
    'title' : 'A modern homage to Danish design legacy',
    'glyphs' : 215,
    'year' : 2016,
    'desc': 'The typeface combines Danish type tradition with innovation and is a loud voice that promotes the power of design. The result is a visual identity based around the unique chunky and sculptural typeface, ‘Danish’ and a simple graphical universe.',
    'descJa': 'デンマークデザインアワード(DDA)のためのタイプフェイス「Danish」。デンマークのデザインの伝統と、偉大なデザイナーであるクヌードV.エンゲルハートからインスピレーションを得て、伝統と革新が同時に表現された、独特で彫刻のような、デザインの持つパワーを高める力強いタイプフェイスが出来上がった。',
    'vidoes': [{
      label : 'Inspiration',
      videoId: 396818728,
    },{
      label : 'Specimen',
      videoId: 394199442,
    },{
      label : 'Spotlight',
      videoId: 396818838,
    },{
      label : 'Application',
      videoId: 394198891,
    }],
    'obj': dda,
    transition: 'down',
  }];

  const {videos} = useStaticQuery(graphql`{
    
    videos : allVimeo {
      nodes {
        aspectRatio,
        id
        duration
        srcset {
          link
          size
          width
          height
        }
        pictures {
          uri
          sizes {
            width
            link
          }
          active
        }
      }
    }
  }`);

  return (
    <>
      <SEO title="Home" />
      <Overview data={data} videos={videos} transitionStatus={transitionStatus} />
    </>
  )
}

export default IndexPage
