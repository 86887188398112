import React, { useRef, useEffect } from "react"
import * as THREE from "three"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js"
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js"
import { RectAreaLightUniformsLib } from "three/examples/jsm/lights/RectAreaLightUniformsLib.js"
import gsap from "gsap"
import detect from "../../../utils/detect"
import letterAnimation from '../lettersAnimation';
import {clamp,isLoaded} from '../../../utils';

import {
  createScreen,
  createStaticWall,
  createFloor,
  resizeScreen,
  getScreenSize,
  setTransparency,
  updateGeometry,
  createCubeCamera,
  getViewSizeAtDepth,
  Filmgrain,
} from "./canvasHelpers"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
//
const Wrapper = ({ children, onOverview, forwaredRef }) => {
  return (
    <div
      ref={forwaredRef}
      className="absolute top-0 left-0 w-full h-full select-none"
      cursor={onOverview ? "explore" : "default"}
    >
      {children}
    </div>
  )
}
/**
    [] Don't allow the user to change overview view while transitioning
    [] Load to be played before
    [] Maybe have a "master scale" THREE.Vector3. That all meshes copy when they are going to be visible. 
          That might help with resizing issue. 
 */

const USE_ORBIT = false
let config = {
  shadowMapSize: 1024,
  smallScale: 0.5,
}

const Canvas = ({
  data,
  video,
  onOverview,
  aspectRatio,
  current,
  onSelect,
  forwaredRef,
}) => {
  const firstRender = useRef(true)
  const canvas = useRef(null)
  const store = useRef({
    w: 0,
    h: 0,
    mouseX: 170,
    mouseY: 0,
    scene: null,
    renderer: null,
    camera: null,
    cubeCamera: null,
    screen: null,
    raf: null,
    clock: null,
    backLight: null,
    foreLight: null,
    projectionCamera: null,
    projectionCameraHelper: null,
    ambientlight: null,
    directionalLight2: null,
    filmGrain: null,
    items: [],
    floor: null,
    previous: 0,
    positionOffset: 0,
    videoTexture: null,
    touchStartX: 0,
    touchStartY: 0,
    mouseStartX:0,
    mouseStartY:0,
    scaleMultiplier: 1, // We need it in the store because the resize function can't read the onOverview props because it's only binded at first render
  })

  const perspective = 1400
  // const duration = 0.95
  // const ease = "power2.inOut"


  const isFacebookApp = () =>  {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
  }

  const addEvents = () => {
    if(detect.isMobile()) {
      canvas.current.addEventListener("touchstart", touchStart);
    } else {
      window.addEventListener("mousemove", mouseMove)
    }
  }

  useEffect(() => {
    store.current.w = window.innerWidth
    store.current.h = window.innerHeight

    if (store.current.w / store.current.h < 1) {
      store.current.positionOffset = 70
    }

    setup()

    window.addEventListener("resize", resize);

    if(isFacebookApp()) {
      setTimeout(() => {
        resize();
      },3000);
    }

    if (isLoaded()) {
      addEvents();
    } else {
      window.addEventListener("loadingDone", () => {
        addEvents();
      });
    }

    return () => {
      window.removeEventListener("resize", resize)
      window.removeEventListener("mousemove", mouseMove)
      canvas.current.removeEventListener("touchstart", touchStart);
      cancelAnimationFrame(store.current.raf)
    }
  }, [canvas])

  useEffect(() => {
    if (firstRender.current) return

    if (!onOverview) {
      showPlayer()
      store.current.scaleMultiplier = config.smallScale
    } else {
      hidePlayer()
      store.current.scaleMultiplier = 1
    }
  }, [onOverview])

  useEffect(() => {
    if (!firstRender.current) {
      updateSlider(current)
    }

    firstRender.current = false
  }, [current])

  useEffect(() => {
    resizeScreens()
  }, [aspectRatio])

  const setup = () => {
    store.current.scene = new THREE.Scene()
    store.current.scene.background = new THREE.Color(0x000000)
    store.current.clock = new THREE.Clock()

    store.current.renderer = new THREE.WebGLRenderer({
      canvas: canvas.current,
      alpha: false, // No alpha is more performance. SInce background is black  and static we don't need it
      antialias: false, // We are doing fxaa on the filmgrain pass
      stencil: false,
    })

    store.current.renderer.shadowMap.enabled = true
    // store.current.renderer.shadowMap.type = THREE.PCFSoftShadowMap // default THREE.PCFShadowMap

    // store.current.scene.background = new THREE.Color( 'yellow' );

    store.current.renderer.setSize(store.current.w, store.current.h)
    store.current.renderer.setPixelRatio(window.devicePixelRatio)

    store.current.filmgrain = new Filmgrain(store.current.w, store.current.h)
    store.current.filmgrain.setSize(store.current.w, store.current.h)

    initCamera()
    initSlider()
    InitScreen()
    initLights()

    update()
  }


  const resize = () => {
    store.current.w = window.innerWidth;
    store.current.h = window.innerHeight;

    if (store.current.w / store.current.h < 1 || store.current.w < 750) {
      store.current.positionOffset = 70
    } else {
      store.current.positionOffset = 0
    }

    store.current.camera.aspect = store.current.w / store.current.h

    store.current.camera.fov =
      (180 * (2 * Math.atan(store.current.h / 2 / perspective))) / Math.PI
    store.current.camera.updateProjectionMatrix()
    store.current.items.map((item, index) => {
      let ratio = setSize(item, true)
      item.scale.set(
        ratio * store.current.scaleMultiplier,
        ratio,
        ratio * store.current.scaleMultiplier
      )
      setPosition(item, index)

      item.material.uniforms.uThresholdScale.value = Math.min(
        1,
        store.current.w / 800
      )
    })

    resizeScreens()

    store.current.renderer.setSize(store.current.w, store.current.h)
    store.current.filmgrain.setSize(store.current.w, store.current.h)
    store.current.renderer.setPixelRatio(Math.min(2, window.devicePixelRatio))
  }
  
  const touchStart = e => {

    store.current.touchStartX = e.targetTouches[0].clientX;
    store.current.touchStartY = e.targetTouches[0].clientY;
    store.current.mouseStartX = store.current.mouseX;
    store.current.mouseStartY = store.current.mouseY;
    window.addEventListener('touchmove',touchmove );
    window.addEventListener('touchend',touchend );
  }

  const touchend = e => {
    window.removeEventListener('touchmove',touchmove );
    window.removeEventListener('touchend',touchend );
  }

  const touchmove = e => {
    store.current.mouseX = clamp(store.current.mouseStartX - (e.targetTouches[0].clientX - store.current.touchStartX), -175, 380);
    store.current.mouseY = clamp(store.current.mouseStartY - (e.targetTouches[0].clientY - store.current.touchStartY), -175, 380);
  }

  const mouseMove = e => {
    store.current.mouseX = e.clientX
    store.current.mouseY = e.clientY
  }

  const initCamera = () => {
    const fov =
      (180 * (2 * Math.atan(store.current.h / 2 / perspective))) / Math.PI

    store.current.camera = new THREE.PerspectiveCamera(
      fov,
      store.current.w / store.current.h,
      1,
      8000
    )

    store.current.scene.add(store.current.camera)

    let projectionCamera = new THREE.PerspectiveCamera(45, 1, 0.01, perspective)
    projectionCamera.position.set(0, 400, perspective)
    projectionCamera.lookAt(0, 0, 0)

    projectionCamera.updateMatrixWorld()
    store.current.projectionCamera = projectionCamera

    // let helper = new THREE.CameraHelper(projectionCamera)
    // store.current.projectionCameraHelper = helper
    // store.current.scene.add(helper)

    store.current.camera.position.set(0, 0, perspective)
    if (USE_ORBIT) {
      let controls = new OrbitControls(store.current.camera, canvas.current)
    }
  }

  const initLights = () => {
    store.current.ambientlight = new THREE.AmbientLight(0x303030)
    store.current.scene.add(store.current.ambientlight)
    // let directionalLight = new THREE.DirectionalLight(0xffffff, 0)
    // directionalLight.position.set(0, 400, perspective)
    // store.current.scene.add(directionalLight)
    // directionalLight.castShadow = true

    let directionalLight2 = new THREE.DirectionalLight(0x808080, 1.2)
    directionalLight2.position.set(0, 200, perspective * 0.3)
    store.current.scene.add(directionalLight2)
    directionalLight2.castShadow = true
    store.current.directionalLight2 = directionalLight2
    // let directionalHelper = new THREE.DirectionalLightHelper(directionalLight2)
    // store.current.scene.add(directionalHelper)
    directionalLight2.shadow.mapSize.width = config.shadowMapSize // default
    directionalLight2.shadow.mapSize.height = config.shadowMapSize // default
    directionalLight2.shadow.camera.near = 0.5 // default
    directionalLight2.shadow.camera.far = 5000 // default
    directionalLight2.shadow.camera.left = -500 // default
    directionalLight2.shadow.camera.right = 500 // default
    directionalLight2.shadow.camera.bottom = -300 // default
    directionalLight2.shadow.camera.top = 300 // default
    // store.current.backLight = new THREE.PointLight(0xaaaaaa, 0, 0)
    let size = 50000
    store.current.backLight = new THREE.PointLight(0x707075, 7, 4000)
    //
    store.current.backLight.position.set(400, -500, -2200)
    store.current.scene.add(store.current.backLight)

    // var helper = new THREE.CameraHelper(directionalLight2.shadow.camera)
    // store.current.scene.add(helper)

    // let backHelper = new THREE.PointLightHelper(store.current.backLight, 50)
    // store.current.scene.add(backHelper)
    // backHelper.frustumCulled = false
    store.current.backLight.frustumCulled = false

    // var backLight = new THREE.PointLight(0xffffff, 0.8)
    // backLight.position.set(-400, 200, -400)
    // store.current.scene.add(backLight)

    // store.current.foreLight = new THREE.PointLight(0x21233a, 0.8)
    store.current.foreLight = new THREE.PointLight(0x21233a, 0)

    store.current.foreLight.position.set(0, 200, 2000)
    store.current.scene.add(store.current.foreLight)
  }

  const InitScreen = () => {
    store.current.screen = createScreen(getVideoTexture())
    store.current.staticWall = createStaticWall()
    store.current.staticWall.position.z = -0.4
    store.current.floor = createFloor()

    // RectAreaLightUniformsLib.init()

    // let rectLight = new THREE.RectAreaLight(0xffffff, 20, 1, 1)
    // rectLight.position.set(0, 0, 200)

    // rectLight.lookAt(0, 0, 0)
    // // rectLight.updateMatrix()
    // // rectLight.updateMatrixWorld()
    // // rectLight.updateProjectionMatrix()
    // store.current.rectLight = rectLight
    // let rectLightHelper = new RectAreaLightHelper(rectLight)
    // store.current.scene.add(rectLightHelper)
    // store.current.scene.add(rectLight)
    // store.current.screen.visible = false
    // store.current.floor.visible = false

    let room = new THREE.Object3D()
    room.add(store.current.screen)
    room.add(store.current.floor)
    room.add(store.current.staticWall)
    room.visible = false;
    store.current.room = room

    store.current.scene.add(room)
    // store.current.scene.add(store.current.floor)
    // store.current.scene.add(store.current.staticWall)

    setTransparency(store.current.floor);
    resizeScreens();

  }

  const resizeScreens = () => {
    let size = getScreenSize(store.current.w, store.current.h, aspectRatio)
    let viewSize = getViewSizeAtDepth(0, store.current.camera)

    store.current.screen.scale.set(size.width, size.height, 1000)
    store.current.staticWall.scale.set(size.width, size.height*2, 1000)
    store.current.staticWall.position.set(0, 0, -0.4)
    let floorSize = Math.max(viewSize.width, viewSize.height)
    store.current.floor.scale.set(floorSize * 3, -floorSize * 3, 1000)
    store.current.floor.position.set(0, -size.height / 2, floorSize * 1.5)

    let projectionCamera = store.current.projectionCamera
    if (projectionCamera) {
      const fov =
        (180 * (2 * Math.atan(size.height / 2 / perspective))) / Math.PI
      projectionCamera.aspect = size.width / size.height
      projectionCamera.fov = fov

      projectionCamera.updateMatrixWorld()
      projectionCamera.updateProjectionMatrix()
      if (store.current.projectionCameraHelper)
        store.current.projectionCameraHelper.update()
    }
    // resizeScreen(
    //   store.current.screen,
    //   store.current.w,
    //   store.current.h,
    //   aspectRatio
    // )
    // resizeScreen(
    //   store.current.floor,
    //   store.current.w,
    //   store.current.h,
    //   aspectRatio
    // )
    // positionFloor(store.current.floor)
    // store.current.floor.scale.y = store.current.floor.scale.y * -1
    //   mesh.position.set(0, mesh.scale.y / 2, (mesh.scale.y / 2) * -1)
    store.current.floor.rotation.x = -Math.PI / 2
  }

  const showPlayer = () => {
    var currentItem = store.current.items[current]

    // currentItem.position.z =
    //   currentItem.geometry.userData.height * currentItem.scale.y
    var ratio = setSize(currentItem, true)
    // currentItem.scale.set(ratio * 0.5, ratio, ratio * 0.5)
    gsap.to(currentItem.scale, {
      x: ratio * config.smallScale,
      y: ratio,
      z: ratio * config.smallScale,
      duration: 0.8,
      ease: "power2.inOut",
    })
    gsap.to(currentItem.position, {
      y: 0,
      duration: 0.8,
      ease: "power2.inOut",
    })

    currentItem.material.uniforms.uDisableProjection.value = false
    // currentItem.material.uDisableProjection

    // store.current.room.visible = true
    // store.current.room.scale.set(1.5, 1.5, 1.5)

    gsap.to(store.current.directionalLight2, {
      intensity: 1.1,
      duration: 0.8,
      ease: "power2.inOut",
    })

    gsap.to(store.current.room.scale, {
      x: 1,
      y: 1,
      z: 1,
      duration: 0.9,
      ease: "power2.inOut",
    })
    gsap.to(store.current.room.position, {
      z: 0,
      y: 0,
      duration: 0.8,
      ease: "power2.inOut",
    })
    gsap.to(store.current.room.rotation, {
      x: 0,
      duration: 1,
      ease: "expo.inOut",
    })

    let roomOpacity = { value: 0 }
    gsap.to(roomOpacity, {
      value: 1,
      duration: 1,
      ease: "power3.inOut",
      onStart: () => {
        store.current.room.visible = true;
      },
      onUpdate: () => {
        store.current.room.children.forEach(mesh => {
          if (mesh.material.uniforms && mesh.material.uniforms.uOpacity) {
            mesh.material.uniforms.uOpacity.value = roomOpacity.value
            return
          }
          mesh.material.opacity = roomOpacity.value
          currentItem.material.uniforms.uProjectionOpacity.value =
            roomOpacity.value
        })
      },
    })
    // store.current.floor.visible = true
    // store.current.screen.visible = true
    store.current.screen.receiveShadow = true
  }

  const hidePlayer = () => {
    if (firstRender.current) return

    var currentItem = store.current.items[current]
    let ratio = setSize(currentItem, true)

    // currentItem.position.z = 0

    gsap.to(store.current.directionalLight2, {
      intensity: 1.2,
      duration: 0.8,
      ease: "power2.inOut",
    })

    gsap.to(store.current.room.scale, {
      x: 1.5,
      y: 1.5,
      z: 1.5,
      duration: 0.8,
      ease: "power2.inOut",
    })
    gsap.to(store.current.room.position, {
      z: -100,
      y: 50,
      duration: 0.8,
      ease: "power2.inOut",
    })

    gsap.to(store.current.room.rotation, {
      x: 0.3,
      duration: 1.1,
      ease: "power1.inOut",
      onComplete: () => {
        currentItem.material.uniforms.uDisableProjection.value = true
      },
    })

    gsap.to(currentItem.scale, {
      x: ratio,
      y: ratio,
      z: ratio,
      duration: 0.8,
      ease: "power2.inOut",
    })
    gsap.to(currentItem.position, {
      y: store.current.positionOffset,
      duration: 0.8,
      ease: "power2.inOut",
    })

    let roomOpacity = { value: 1 }
    gsap.to(roomOpacity, {
      value: 0,
      duration: 0.9,
      ease: "expo.inOut",
      onComplete: () => {
        store.current.room.visible = false;
      },
      onUpdate: () => {
        store.current.room.children.forEach(mesh => {
          if (mesh.material.uniforms && mesh.material.uniforms.uOpacity) {
            mesh.material.uniforms.uOpacity.value = roomOpacity.value
            return
          }
          mesh.material.opacity = roomOpacity.value
          currentItem.material.uniforms.uProjectionOpacity.value =
            roomOpacity.value
        })
      },
    })
    // store.current.room.visible = false

    // store.current.screen.visible = false
    // store.current.floor.visible = false
  }

  const getVideoTexture = () => {
    // We only need to create the video texture once assuming the actual video element isn't changed
    if (store.current.videoTexture) {
      return store.current.videoTexture
    }

    var texture = new THREE.VideoTexture(video.current)

    texture.minFilter = THREE.LinearFilter
    texture.magFilter = THREE.LinearFilter

    store.current.videoTexture = texture

    return texture
  }

  const initSlider = () => {
    data.map((item, index) => {
      if (item.obj) {
        addItem(item.obj, index)
      }
    })
  }

  const setSize = (mesh, dontSet) => {
    var ratio = 1;

    // let width = box.max.x - box.min.x
    let width = mesh.geometry.userData.width
    
    // let height = box.max.y - box.min.y
    let height = mesh.geometry.userData.depth
    

    let heightRatio;
    let widthRatio;

    if (store.current.h > store.current.w) {
      heightRatio = (store.current.h) / height;
      widthRatio = (store.current.w - (store.current.w * 0.10)) / width
    } else {
      heightRatio = (store.current.h / 3.5) / height;
      widthRatio = (store.current.w - 125) / width
    }
    
    ratio = Math.min(heightRatio, widthRatio)
    if (!dontSet) {
      mesh.scale.set(ratio, ratio, ratio)
    } else {
      return ratio
    }
  }

  const setPosition = (mesh, index) => {
    mesh.posX = store.current.w * index
    if (store.current.scaleMultiplier === 1)
      mesh.position.y = store.current.positionOffset
    else mesh.position.y = 0
  }

  const addItem = (file, index) => {
    var loader = new OBJLoader()
    // const fov =
    //   (180 * (2 * Math.atan(store.current.h / 2 / perspective))) / Math.PI

    // store.current.camera = new THREE.PerspectiveCamera(
    //   fov,
    //   store.current.w / store.current.h,
    //   1,
    //   5000
    // )
    // let projectionCamera = new THREE.PerspectiveCamera(45, 1, 0.01, perspective)
    // projectionCamera.position.set(0, 0, perspective)
    // projectionCamera.lookAt(0, 0, 0)

    // projectionCamera.updateMatrixWorld()
    // store.current.projectionCamera = projectionCamera

    // let helper = new THREE.CameraHelper(projectionCamera)
    // store.current.projectionCameraHelper = helper
    // store.current.scene.add(helper)
    let projectionCamera = store.current.projectionCamera

    let videoTexture = getVideoTexture()

    loader.load(file, function(object) {
      object.traverse(function(child) {
        if (child instanceof THREE.Mesh) {
          // child.geometry.computeVertexNormals()
          //   child.geometry.getSize()
          child.rotation.x = 1.5708
          child.geometry.center()
          child.geometry.userData.width =
            child.geometry.boundingBox.max.x - child.geometry.boundingBox.min.x
          child.geometry.userData.height =
            child.geometry.boundingBox.max.y - child.geometry.boundingBox.min.y
          child.geometry.userData.depth =
            child.geometry.boundingBox.max.z - child.geometry.boundingBox.min.z
          child.material = new THREE.MeshPhongMaterial({
            color: 0x101010,
            shininess: 35,
            depthTest: true,
            dithering: true,
            // wireframe: true,
          })
          child.renderOrder = 100
          let uniforms = {
            uTexture: new THREE.Uniform(videoTexture),
            uCameraProjection: new THREE.Uniform(
              projectionCamera.projectionMatrix
            ),
            uCameraMatrix: new THREE.Uniform(
              projectionCamera.matrixWorldInverse
            ),
            uThreshold: new THREE.Uniform(index === 3 ? 0.1 : 2000.1),
            uThresholdScale: new THREE.Uniform(1),
            uCameraPosition: new THREE.Uniform(projectionCamera.position),
            uDisableProjection: new THREE.Uniform(onOverview),
            uProjectionOpacity: new THREE.Uniform(onOverview ? 0 : 1),
          }
          child.material.uniforms = uniforms
          child.castShadow = true

          child.material.onBeforeCompile = shader => {
            let keys = Object.keys(uniforms)
            keys.forEach(key => {
              shader.uniforms[key] = uniforms[key]
            })
            // shader.uniforms.uTexture = new THREE.Uniform(videoTexture)
            // shader.uniforms.uCameraProjection = new THREE.Uniform(
            //   projectionCamera.projectionMatrix
            // )
            // shader.uniforms.uCameraMatrix = new THREE.Uniform(
            //   projectionCamera.matrixWorldInverse
            // )
            // shader.uniforms.uCameraPosition = new THREE.Uniform(
            //   projectionCamera.position
            // )
            // shader.uniforms.uDisableProjection = new THREE.Uniform(true)

            let pars = `
                uniform sampler2D uTexture;
                uniform mat4 uCameraMatrix;
                uniform mat4 uCameraProjection;
                uniform vec3 uCameraPosition;
                uniform bool uDisableProjection;
                uniform float uThreshold;
                uniform float uThresholdScale;
                uniform float uProjectionOpacity;
                varying vec4 vWorldPosition;
                varying vec4 vProjectedCoords;
            `

            shader.vertexShader =
              pars +
              shader.vertexShader.replace(
                "#include <project_vertex>",
                ` 
                vWorldPosition = modelMatrix * vec4(transformed, 1.);
                vec4 projectedCoords =  uCameraProjection * uCameraMatrix * vWorldPosition; 
                vProjectedCoords = projectedCoords;
                vNormal = mat3(modelMatrix) * normal;


                vec4 mvPosition = modelViewMatrix * vec4(transformed,1.);
                gl_Position = projectionMatrix * mvPosition;
                `
              )

            shader.fragmentShader =
              pars +
              shader.fragmentShader.replace(
                `#include <color_fragment>`,
                `
                vec2 projectionUV  = (vProjectedCoords.xy / vProjectedCoords.w) * 0.5 + 0.5;
                vec4 tex = texture2D(uTexture, projectionUV);
                vec2 isInside = step(vec2(0.),projectionUV) *  step(projectionUV, vec2(1.)) ;
                float isInside1 = isInside.x * isInside.y;
                vec3 color = tex.rgb;

                vec3 cameraRelativePosition = normalize(uCameraPosition - vWorldPosition.xyz);
                float dotProduct = dot(vNormal, cameraRelativePosition);
                if (dotProduct < uThreshold * uThresholdScale ) {
                // Get some dark color on the sides instead of all black. 
                // Not going to be super accurate but does the job
                // color = mix(color, vec3(0.), (1.-(max(0., dotProduct) /0.001)) * 0.8 );
                // Or just make it black
                color = tex.rgb * vec3(0.2);
                }

            	diffuseColor = vec4( mix(diffuse, color, uProjectionOpacity * isInside1) , opacity ); // The actual color
                if(uDisableProjection){
                    diffuseColor = vec4( diffuse, opacity ); // The actual color
                }
            	// diffuseColor = vec4( isInside1); // The actual color

                `
              )
          }

          child.material.transparent = true
          child.material.opacity = 1

          let ratio = setSize(child, true)
          let multiplier = onOverview ? 1 : config.smallScale
          child.scale.set(ratio * multiplier, ratio, ratio * multiplier)

          setPosition(child, index)

          let wrapperZOffset = -800

          child.position.z =
            (child.geometry.userData.height * child.scale.y) / 2 -
            wrapperZOffset
          if (index !== current) {
            child.visible = false
          }

          let wrapper = new THREE.Object3D()
          wrapper.position.z += wrapperZOffset
          wrapper.add(child)

          store.current.scene.add(wrapper)
          store.current.items[index] = child
        }
      })
    })
  }

  const updateSlider = (current) => {
    if (!onOverview) return;

    var currentItem = store.current.items[current]
    var prevItem = store.current.items[store.current.previous]

    currentItem.material.uniforms.uDisableProjection.value = true;
    
    setSize(currentItem);
    letterAnimation(prevItem,currentItem, store, data[current].transition);
    
    store.current.previous = current;
  }

  const update = () => {
    store.current.raf = requestAnimationFrame(update)

    store.current.backLight.position.set(
      store.current.mouseX - store.current.w / 2,
      store.current.h / 2 - store.current.mouseY,
      store.current.backLight.position.z
    )
    store.current.foreLight.position.set(
      store.current.mouseX - store.current.w / 2,
      store.current.h / 2 - store.current.mouseY,
      2000
    )
    if (!USE_ORBIT) {
      let progress =
        ((store.current.mouseX - store.current.w / 2) / store.current.w) * 2
      let progressY =
        ((-store.current.mouseY + store.current.h / 2) / store.current.h) * 2

      // store.current.camera.position.setX(
      //   ((store.current.mouseX - store.current.w / 2) / store.current.w) * 200
      // )
      store.current.camera.userData.targetZ =
        Math.cos(progress) * 400 + perspective - 400
      store.current.camera.userData.targetX = Math.sin(progress) * 800
      store.current.camera.userData.targetY = progressY * 100

      // store.current.camera.position.z = Math.cos(progress) * 200 + perspective
      // store.current.camera.position.x = Math.sin(progress) * 400
      // store.current.camera.position.y = progressY * 100

      store.current.camera.position.z +=
        (store.current.camera.userData.targetZ -
          store.current.camera.position.z) *
        0.07
      store.current.camera.position.x +=
        (store.current.camera.userData.targetX -
          store.current.camera.position.x) *
        0.07
      store.current.camera.position.y +=
        (store.current.camera.userData.targetY -
          store.current.camera.position.y) *
        0.07

      store.current.camera.lookAt(0, 0, 0)
      store.current.camera.rotation.z = progress * 0.01  
    }

    // store.current.camera.rotation.x = store.current.camera.rotation.x * 0.8

    // store.current.camera.rotation.x =
    //   ((-store.current.mouseY + store.current.h / 2) / store.current.h) * 0.05

    let renderer = store.current.renderer
    let filmgrain = store.current.filmgrain
    filmgrain.setTime(store.current.clock.getElapsedTime())

    var currentRenderTarget = renderer.getRenderTarget()

    renderer.setRenderTarget(filmgrain.renderTarget)
    renderer.render(store.current.scene, store.current.camera)
    renderer.setRenderTarget(currentRenderTarget)
    renderer.render(filmgrain.scene, filmgrain.camera)
  }

  return (
    <Wrapper onOverview={onOverview} forwaredRef={forwaredRef}>
      <canvas
        onClick={onSelect}
        className="absolute top-0 left-0 w-full h-full"
        ref={canvas}
      />
    </Wrapper>
  )
}

export default Canvas
